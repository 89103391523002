import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import SEO from 'components/SEO'
import Layout from 'components/Layout'
import Header from 'components/Header'
import Footer from 'components/Footer'
import IntegrationsIntro from 'components/Integrations-screen/Integrations-intro'
import IntegrationJoin from 'components/Integrations-screen/Integrations-join'
import IntegrationsContent from '../components/Integrations-screen/Integrations-content'

const MainContent = styled.main`
  background-color: #ffffff;
  position: relative;
  z-index: 1;
`

const Integrations = ({ data, pageContext, location }) => {
  const {
    integrations: {
      featured_media,
      title,
      excerpt,
      translations,
      lang,
      yoast_meta: { yoast_wpseo_title, yoast_wpseo_metadesc },
      acf: { title: integration_title, subtitle, partner_program }
    },
    apps: { edges: apps }
  } = data

  const featuredImage = featured_media !== null ? featured_media?.localFile?.childImageSharp : null
  
  const seo = {
    title: yoast_wpseo_title || title,
    description: yoast_wpseo_metadesc || excerpt,
    lang: pageContext.locale,
    image: featuredImage ? featuredImage.resize : null,
    pathname: location.pathname
  }
  
  return (
    <Layout locale={pageContext.locale}>
      <SEO
        title={seo.title}
        description={seo.description}
        pathname={seo.pathname}
        lang={seo.lang}
        image={seo.image}
        location={location}
        />
      <Header mode="dark" locale={pageContext.locale} opacity={0.3} location={location} translations={translations} language={lang}/>
      <IntegrationsIntro data={{ integration_title, subtitle }} />
      <MainContent>
        <IntegrationsContent apps={apps} />
        <IntegrationJoin data={partner_program} />
      </MainContent>
      <Footer locale={pageContext.locale} location={location} />
    </Layout>
  )
}

export default Integrations

export const query = graphql`
fragment AppImages on wordpress__wp_media {
  localFile {
    childImageSharp {
      fixed(quality: 100, width: 60) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
}
query($id: Int, $locale: String!) {
  integrations: wordpressPage(wordpress_id: { eq: $id }, lang: { eq: $locale }) {
    title
    excerpt
    translations {
      en
      hr
    }
    lang
    yoast_meta {
      yoast_wpseo_title
      yoast_wpseo_metadesc
    }
    featured_media {
          localFile {
            childImageSharp {
              fluid(quality: 85, maxWidth: 1300) {
                ...GatsbyImageSharpFluid_withWebp
              }
              resize(width: 1200) {
                src
                height
                width
              }
            }
          }
        }
      acf {
        title
        subtitle
        partner_program {
          title
          description
          button_text
          button_link
        }
      }
    }

    apps: allWordpressWpIntegrations(filter: { lang: { eq: $locale } }, sort: { order: ASC, fields: date }) {
      edges {
        node {
          id
          title
          acf {
            image {
              ...AppImages
            }
            new_app
            description
          }
        }
      }
    }
  }
`
