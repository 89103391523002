import styled from 'styled-components'

export const IntegrationsContentSection = styled.section`
  padding: 8rem 0;

  @media screen and (max-width: 425px) {
    padding: 2rem 0;
  }
`

export const IntegrationsCategoriesFilter = styled.ul`
  display: flex;
  margin: 7rem 0;

  li {
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: 1.14rem;
    line-height: 1.71rem;
    padding: 1rem 0;
    cursor: pointer;

    &:not(:first-child) {
      margin: 0 2rem;
    }

    &:hover {
      color: ${({ theme }) => theme.colors.vividBlue};
    }
  }

  @media only screen and (max-width: 540px) {
    justify-content: center;

    li:not(:first-child) {
      margin: 0 0.5rem;
    }
  }
`

export const IntegrationsCategoriesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 6rem 6rem;
  justify-items: center;
  margin-bottom: 10rem;

  @media only screen and (max-width: 540px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 5rem 3rem;
    margin-bottom: 4rem;
  }

  @media only screen and (min-width: 541px) and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const AppBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 320px;
  height: auto;

  @media only screen and (max-width: 540px) {
    align-items: center;
    text-align: center;
  }
`

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  height: 47px;
  figure {
    display: flex;
    align-items: center;
    max-width: 60px;
    width: 100%;
    height: 100%;
  }

  @media only screen and (max-width: 540px) {
    flex-direction: column;
    height: 100px;
    figure {
      display: block;
      /* align-items: center; */
      max-width: 60px;
      width: 100%;
      height: 100%;
    }
    span {
      margin-left: 0;
      transform: translateY(-8px);
    }
  }
`

export const Tag = styled.span`
  color: ${({ theme }) => theme.colors.lightestGrey};
  background-color: ${({ theme }) => theme.colors.vividBlue};
  font-size: 0.85rem;
  font-weight: 500;
  line-height: 1;
  border-radius: 20px;
  text-transform: uppercase;
  padding: 0.85rem;
  margin-left: 1.71rem;
`
export const AppName = styled.h5`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 2.14rem;
  font-weight: 800;
  line-height: 2.85rem;
  margin: 1.42rem 0;

  @media only screen and (max-width: 425px) {
    margin-top: 0.5rem;
  }
`
export const Description = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1.14rem;
  line-height: inherit;
  margin-bottom: auto;

  a {
    color: ${({ theme }) => theme.colors.vividBlue};

    &:hover {
      text-decoration: underline;
    }
  }
`

export const Category = styled.span`
  color: ${({ theme }) => theme.colors.darkGrey};
  line-height: 1.71rem;
  border: 1px solid ${({ theme }) => theme.colors.veryLightGray};
  border-radius: 8px;
  padding: 0.35rem 0.8rem;
  align-self: flex-start;
  cursor: pointer;
  margin-top: 2rem;

  @media only screen and (max-width: 540px) {
    align-self: center;
  }
`
