import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { IntegrationsIntroWrapper, Row, Column, Title, Subtitle, ImageContainer, Shadow } from './styles'
import Container from '../../UI/Container'

const IntegrationsIntro = ({ data }) => {
  const { integration_title, subtitle } = data
  const {
    file: {
      childImageSharp: { fluid }
    }
  } = useStaticQuery(graphql`
    fragment IntegrationsImage on File {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query {
      file(relativePath: { eq: "images/integrations.png" }) {
        ...IntegrationsImage
      }
    }
  `)

  return (
    <IntegrationsIntroWrapper>
      <Container>
        <Shadow />
        <Row>
          <Column>
            <Subtitle>{subtitle}</Subtitle>
            <Title>{integration_title}</Title>
          </Column>
          <Column>
            <ImageContainer>
              <Img fluid={fluid} placeholderStyle={{ visibility: 'hidden' }} />
            </ImageContainer>
          </Column>
        </Row>
      </Container>
    </IntegrationsIntroWrapper>
  )
}

export default IntegrationsIntro
