import styled from 'styled-components'

import JoinImg from '../../../assets/images/team-5.jpg'

export const IntegrationJoinSection = styled.section`
  padding: 8rem 0;

  @media only screen and (max-width: 1024px) {
    padding: 2rem 0;
  }
`

export const IntegrationJoinWrapper = styled.div`
  display: flex;
  box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.2);
  border-radius: 8px;
  overflow: hidden;
  height: 310px;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    height: auto;
  }
`

export const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem 4rem;
  background: radial-gradient(91.53% 537.19% at 34.46% 108.61%, #0d3151 0%, #134776 100%);

  > a {
    margin: 0;
  }

  @media only screen and (max-width: 1024px) {
    text-align: center;
    align-items: center;
    flex-basis: 100%;
    padding: 5.28rem 2rem;
  }
`

export const ColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 40%;
  align-items: center;
`

export const Title = styled.h3`
  font-family: 'Barlow';
  color: ${({ theme }) => theme.colors.lightestGrey};
  font-size: 2.85rem;
  font-weight: 800;
  line-height: 3.57rem;
  margin-bottom: 2.5rem;

  @media only screen and (max-width: 768px) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
`

export const Description = styled.div`
  color: ${({ theme }) => theme.colors.lightestGrey};
  font-size: 1.14rem;
  line-height: 1.71rem;
  margin-bottom: 1rem;

  @media screen and (max-width: 425px) {
    margin-bottom: 3rem;
  }
`
export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  background: url(${JoinImg}) no-repeat;
  background-size: cover;
  background-position: center;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    height: 300px;
  }
`
