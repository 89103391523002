import React from 'react'
import Img from 'gatsby-image'

import {
  IntegrationsContentSection,
  IntegrationsCategoriesFilter,
  IntegrationsCategoriesGrid,
  AppBoxWrapper,
  ImageContainer,
  Tag,
  AppName,
  Description,
  Category
} from './styles'
import Container from '../../UI/Container'

const IntegrationsContent = ({ apps }) => {
  const AppBox = ({ app }) => {
    const {
      title,
      acf: {
        image: {
          localFile: {
            childImageSharp: { fixed }
          }
        },
        new_app,
        description
      }
    } = app
    return (
      <AppBoxWrapper>
        <ImageContainer>
          <figure>
            <Img fixed={fixed} />
          </figure>
          {new_app && <Tag>New</Tag>}
        </ImageContainer>
        <AppName>{title}</AppName>
        <Description dangerouslySetInnerHTML={{ __html: description }} />
        {/* <Category>Communication</Category> */}
      </AppBoxWrapper>
    )
  }
  return (
    <IntegrationsContentSection>
      <Container>
        {/* <IntegrationsCategoriesFilter>
          <li>All</li>
          <li>CRM</li>
          <li>Communication</li>
          <li>Reporting</li>
        </IntegrationsCategoriesFilter> */}
        <IntegrationsCategoriesGrid>
          {apps.map(app => (
            <AppBox key={app.node.id} app={app.node} />
          ))}
        </IntegrationsCategoriesGrid>
      </Container>
    </IntegrationsContentSection>
  )
}

export default IntegrationsContent
