import React from 'react'
import { Link } from 'gatsby'

import {
  IntegrationJoinSection,
  IntegrationJoinWrapper,
  ColumnLeft,
  ColumnRight,
  Title,
  Description,
  ImageContainer
} from './styles'
import Container from '../../UI/Container'
import Button from '../../Button'

const IntegrationJoin = ({ data }) => {
  const { title, description, button_text, button_link } = data
  return (
    <IntegrationJoinSection>
      <Container>
        <IntegrationJoinWrapper>
          <ColumnLeft>
            <Title>{title}</Title>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
            <Button as={Link} to={button_link} type="small" inverted="true">
              {button_text}
            </Button>
          </ColumnLeft>
          <ColumnRight>
            <ImageContainer />
          </ColumnRight>
        </IntegrationJoinWrapper>
      </Container>
    </IntegrationJoinSection>
  )
}

export default IntegrationJoin
